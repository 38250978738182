/* src/App.css */
body {
  background-color: #f8f9fa;
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container {
  margin-top: 20px;
}

.table {
  margin-top: 20px;
}

.form-control {
  margin-bottom: 10px;
}

button {
  width: 100%;
}

.navbar {
  margin-bottom: 20px;
}
